import React from "react";
import { Media } from "@ryerson/frontend.layout";
import styled from "@emotion/styled";
import { useTheme } from "@ryerson/frontend.theme";
import { Typography } from "@ryerson/frontend.typography";
import { ContentSection, Flex, FlexItem } from "@ryerson/frontend.layout";
import { css } from "@emotion/react";
import { Link } from "@ryerson/frontend.navigation";
import { Icon } from "@ryerson/frontend.assets";
import { useApplication } from "@ryerson/frontend.application";
import rgba from "polished/lib/color/rgba";

export type FileListItem = {
	name: string;
	url?: string;
};

export type FileExplorerContent = {
	title: string;
	description: string;
	fileList: FileListItem[];
};

export type FileExplorerProps = {
	content: FileExplorerContent;
};

const Title = styled(Typography)`
	margin-top: 0px;
	margin-bottom: 20px;
`;

const Description = styled(Typography)`
	margin-top: 20px;
	width: 380px;
`;

const ContentHeader = styled(Flex)`
	width: 100%;
`;

const SafetyDataSheetsContainer = styled.div`
	padding: 40px 60px 60px;
	margin-top: 30px;
	${(props: any) => {
		const { color } = props;
		return css`
			background-color: ${color};
		`;
	}}
`;

const PdfList = styled.ul`
	-moz-column-count: 3;
	-moz-column-gap: 30px;
	-webkit-column-count: 3;
	-webkit-column-gap: 30px;
	column-count: 3;
	column-gap: 30px;
	padding: 0;
`;

const PdfItem = styled.li`
	display: inline-block;
	width: 100%;
	padding-top: 28px;
`;

const PdfIcon = styled(Icon)`
	flex-shrink: 0;
`;

const PdfLink = styled(Link)`
	margin-left: 10px;
`;

const Underline = styled(Flex)`
	padding-bottom: 5px;
	width: fit-content;
`;

const MobilePdfList = styled.ul`
	padding: 0;
`;

const MobilePdfIcon = styled(Icon)`
	flex-shrink: 0;
`;

const MobilePdfItem = styled.li`
	display: flex;
	padding-top: 28px;
`;

const MobilePdfLink = styled(Link)`
	margin: 0px 10px;
`;
const MobileSafetyDataSheetsContainer = styled.div`
	margin-top: 30px;
	margin-bottom: 20px;
`;

const FileExplorer: React.FC<FileExplorerProps> = ({ content }) => {
	const { theme } = useTheme();
	const {
		localization: { language },
	} = useApplication();
	return (
		<>
			<Media lessThan="lg">
				<MobileSafetyDataSheetsContainer>
					<Description
						variant="p"
						size="md"
						css={css`
							width: 100%;
						`}
					>
						{content.description}
					</Description>
					<MobilePdfList>
						{content.fileList.map((safetyDataSheet: FileListItem, index: number) => {
							return safetyDataSheet.url ? (
								<>
									<MobilePdfItem key={index}>
										<MobilePdfIcon
											color={theme.colors.primary.link}
											icon="file-pdf"
											size="sm"
										/>
										<MobilePdfLink
											gatsby={false}
											target="_blank"
											to={`/static-assets/documents/pdfs/${language}/safety-data-sheets/${safetyDataSheet.url}`}
										>
											{safetyDataSheet.name}
										</MobilePdfLink>
									</MobilePdfItem>
								</>
							) : (
								<>
									<MobilePdfItem key={index}>
										<MobilePdfIcon
											color={theme.colors.primary.link}
											icon="dot-circle"
											size="sm"
										/>
										<Typography
											color={theme.colors.primary.link}
											variant="p"
											size="lg"
											css={css`
												margin: 0px;
											`}
										>
											{safetyDataSheet.name}
										</Typography>
									</MobilePdfItem>
								</>
							);
						})}
					</MobilePdfList>
				</MobileSafetyDataSheetsContainer>
			</Media>
			<Media greaterThanOrEqual="lg">
				<ContentSection
					vPadding="30px"
					type="split"
					split={{
						splitDirection: "vertical",
						splitColors: [
							{
								color: theme.colors.tertiary.background,
								percentage: "70%",
							},
							{
								color: theme.colors.secondary.background,
								percentage: "30%",
							},
						],
					}}
				>
					<ContentHeader justifyContent="space-between">
						<FlexItem>
							<Title variant="h2" type="tertiary">
								{content.title}
							</Title>
						</FlexItem>
						<FlexItem css={{ paddingRight: "70px" }}>
							<Description variant="p" type="tertiary">
								{content.description}
							</Description>
						</FlexItem>
					</ContentHeader>
					<SafetyDataSheetsContainer color={theme.colors.primary.darkerGray}>
						<PdfList>
							{content.fileList.map(
								(safetyDataSheet: FileListItem, index: number) => {
									return safetyDataSheet.url ? (
										<>
											<PdfItem key={index}>
												<Underline
													css={css`
														border-bottom: 1px solid
															${rgba(
																theme.colors.primary.lighterGray,
																0.1
															)};
													`}
												>
													<PdfIcon
														color={theme.colors.tertiary.link}
														icon="file-pdf"
														size="sm"
													/>
													<PdfLink
														gatsby={false}
														color={theme.colors.tertiary.link}
														target="_blank"
														to={`/static-assets/documents/pdfs/${language}/safety-data-sheets/${safetyDataSheet.url}`}
													>
														{safetyDataSheet.name}
													</PdfLink>
												</Underline>
											</PdfItem>
										</>
									) : (
										<>
											<PdfItem key={index}>
												<Underline
													css={css`
														border-bottom: 1px solid
															${theme.colors.primary.darkerGray};
													`}
												>
													<PdfIcon
														color={theme.colors.tertiary.link}
														icon="dot-circle"
														size="sm"
													/>
													<Typography
														color={theme.colors.tertiary.link}
														variant="p"
														size="md"
														css={css`
															margin: 0px;
														`}
													>
														{safetyDataSheet.name}
													</Typography>
												</Underline>
											</PdfItem>
										</>
									);
								}
							)}
						</PdfList>
					</SafetyDataSheetsContainer>
				</ContentSection>
			</Media>
		</>
	);
};

export default FileExplorer;
